import type { ReactRouterCompatibleHistory } from '@atlassian/jira-spa-router-adapters/src/common/types.tsx';
import { useUrlState } from '../url-state';
import { useRapidBoard as useRapidBoardClient } from './main';

export const useRapidBoard = __SERVER__
	? (_history: ReactRouterCompatibleHistory) => {
			const { mode } = useUrlState();
			return {
				isRapidBoardsReady: false,
				isBentoEnabled: false,
				mode,
				shouldUserSeeBoard: true,
			};
		}
	: useRapidBoardClient;
