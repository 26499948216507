import React from 'react';
import kebabCase from 'lodash/kebabCase';
import { stringify } from 'query-string';
import { useContainerContext } from '@atlassian/jira-providers-container-context/src/main.tsx';
import { useRouter } from '@atlassian/jira-router';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect/src/ui/index.tsx';

const LEGACY_PATH = '/secure/RapidBoard.jspa';

export const PrettyUrlRedirect = () => {
	const [{ query, location }] = useRouter();
	const [{ data, error }] = useContainerContext();

	if (!data || error) {
		return null;
	}

	const isLegacyRoute = location.pathname === LEGACY_PATH;
	if (!isLegacyRoute) {
		return null;
	}

	// If query contains locationless param then do nothing
	if (query.locationless) {
		return null;
	}

	const { scope } = data;
	const boardId = scope?.id ?? '';

	const prettyUrlQueryParams = {
		...query,
	};

	const mode = prettyUrlQueryParams.view || '';

	let url = '';
	// @ts-expect-error - TS2339 - Property 'project' does not exist on type 'ContainerContext'.
	if (!data.project) {
		// @ts-expect-error - TS2339 - Property 'user' does not exist on type 'ContainerContext'.
		url += `/jira/people/${data.user.accountId}`;
	} else {
		// @ts-expect-error - TS2339 - Property 'project' does not exist on type 'ContainerContext'.
		url += `/jira/software/c/projects/${data.project.key}`;
	}
	url += `/boards/${boardId}`;

	if (mode.includes('planning')) {
		url += '/backlog';
	} else if (mode.includes('reporting')) {
		url += `/reports/${kebabCase(prettyUrlQueryParams.chart)}`;
	}

	delete prettyUrlQueryParams.view;
	delete prettyUrlQueryParams.chart;
	delete prettyUrlQueryParams.rapidView;
	delete prettyUrlQueryParams.projectKey;

	if (mode === 'planning') {
		prettyUrlQueryParams.view = 'detail';
	}

	const queryParams = stringify(prettyUrlQueryParams);
	url += queryParams ? `?${queryParams}` : '';

	return <ScreenRedirect to={url} persistSearchParams={false} />;
};
