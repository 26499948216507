import React from 'react';
import { useContainerContext } from '@atlassian/jira-providers-container-context/src/main.tsx';
import { useQueryParam } from '@atlassian/react-resource-router';
import LazyLocationlessDialog from './async';

export const LocationlessDialog = () => {
	const baseUrl = '';
	const [rapidView] = useQueryParam('rapidView');
	const [{ loading, data }] = useContainerContext();

	if (!loading && !data) {
		const rapidViewId = Number(rapidView);
		return <LazyLocationlessDialog baseUrl={baseUrl} rapidViewId={rapidViewId} />;
	}

	return null;
};
