import React, { type MouseEvent, useCallback } from 'react';
import { AnalyticsListener, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import getLanguageFromLocale from '@atlassian/jira-common-localisation/src/utils/get-language-from-locale.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { IntlProvider } from '@atlassian/jira-intl/src/intl-provider.tsx';
import {
	useAnalyticsEvents,
	getEvent,
	fireUIAnalytics,
	ContextualAnalyticsData,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import JiraRelayEnvironmentProvider from '@atlassian/jira-relay-environment-provider';
import { EditSprintModalEntryPointContainer } from '@atlassian/jira-software-edit-sprint-modal-relay/src/common/utils/edit-sprint-modal-entrypoint-container/index.tsx';
import { useEditSprintModalEntryPoint } from '@atlassian/jira-software-edit-sprint-modal-relay/src/common/utils/use-edit-sprint-modal-entrypoint/index.tsx';
// eslint-disable-next-line import/order
import messages from './messages';

// eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-require-imports
const languagePack = require('language-pack');

export type EditSprintButtonAppProps = {
	boardId: number;
	sprintId: string;
	locale: string;
	onSubmit: () => void;
};

export type EditSprintButtonProps = Omit<EditSprintButtonAppProps, 'locale'>;

export const EditSprintButtonApp = (props: EditSprintButtonAppProps) => {
	const onEvent = useCallback((event: UIAnalyticsEvent) => {
		getAnalyticsWebClientPromise().then((client) => {
			if (client) {
				client.sendEvent(getEvent(event));
			}
		});
	}, []);

	return (
		<JSErrorBoundary
			id="editSprintModalLegacyBridgeEditButtonApp"
			packageName="jiraEditSprintModalLegacyBridge"
			teamName="a4t-pandora"
		>
			<ContextualAnalyticsData sourceType={SCREEN} sourceName="cmpReports">
				<AnalyticsListener channel="*" onEvent={onEvent}>
					<JiraRelayEnvironmentProvider>
						<IntlProvider messages={languagePack} locale={getLanguageFromLocale(props.locale)}>
							<EditSprintButton {...props} />
						</IntlProvider>
					</JiraRelayEnvironmentProvider>
				</AnalyticsListener>
			</ContextualAnalyticsData>
		</JSErrorBoundary>
	);
};

export const EditSprintButton = ({ boardId, sprintId, onSubmit }: EditSprintButtonProps) => {
	const { formatMessage } = useIntl();
	const { entryPointActions, entryPointReferenceSubject, stopMetric, editSprintModalTriggerRef } =
		useEditSprintModalEntryPoint(boardId, sprintId, true);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleOpenEditSprint = useCallback(
		(e: MouseEvent<HTMLAnchorElement>) => {
			e.preventDefault();

			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'dropdownItem',
			});

			fireUIAnalytics(analyticsEvent, 'openEditSprint');
		},
		[createAnalyticsEvent],
	);

	return (
		<>
			{/* eslint-disable jsx-a11y/anchor-is-valid */}
			<a
				id="ghx-edit-sprint"
				role="button"
				href="#"
				ref={editSprintModalTriggerRef}
				onClick={handleOpenEditSprint}
			>
				{formatMessage(messages.editSprint)}
			</a>
			<EditSprintModalEntryPointContainer
				boardId={boardId}
				isCompanyManaged
				entryPointReferenceSubject={entryPointReferenceSubject}
				entryPointActions={entryPointActions}
				onReady={stopMetric}
				// Replace with lodash/noop
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				onClose={() => {}}
				// Replace with lodash/noop
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				onSubmit={onSubmit || (() => {})}
			/>
		</>
	);
};
