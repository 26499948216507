import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useContainerContext } from '@atlassian/jira-providers-container-context/src/main.tsx';
import { useRouter } from '@atlassian/jira-router';
import { useUrlState } from '../../../controllers/url-state';
import { ProjectBreadcrumbs } from './project';
import { UserBreadcrumbs } from './user';

export const Breadcrumbs = () => {
	const [{ query, match }] = useRouter();
	const baseUrl = '';
	const [{ data, error }] = useContainerContext();
	const { formatMessage } = useIntl();
	const { chartView } = useUrlState();

	if (!data || error) {
		return null;
	}

	let boardId;

	if (match && match.params && match.params.boardId !== undefined) {
		boardId = Number(match.params.boardId);
	} else {
		boardId = Number(query.rapidView);
	}

	const boardName = (data.scope && data.scope.name) || '';
	const isReportsView = query && query.view === 'reporting';

	// @ts-expect-error - TS2339 - Property 'user' does not exist on type 'ContainerContext'.
	if (data.user) {
		const accountId = match.params.accountId || '';
		const isLocationlessBoard = !!query.locationless;
		return (
			<UserBreadcrumbs
				accountId={accountId}
				boardId={boardId}
				boardName={boardName}
				isReportsView={isReportsView}
				// @ts-expect-error - TS2339 - Property 'user' does not exist on type 'ContainerContext'.
				profileUrl={data.user.profileUrl}
				// @ts-expect-error - TS2339 - Property 'user' does not exist on type 'ContainerContext'.
				userName={data.user.name}
				chart={chartView}
				formatMessage={formatMessage}
				isLocationlessBoard={isLocationlessBoard}
			/>
		);
	}

	// @ts-expect-error - TS2339 - Property 'project' does not exist on type 'ContainerContext'.
	const { project } = data;
	const projectKey = project.key;
	const projectName = project.name;
	const projectId = project.id;
	const isSimplifiedProject = project.isSimplified;
	return (
		<ProjectBreadcrumbs
			baseUrl={baseUrl}
			boardId={boardId}
			boardName={boardName}
			chart={chartView}
			projectKey={projectKey}
			projectName={projectName}
			projectId={projectId}
			isSimplifiedProject={isSimplifiedProject}
			formatMessage={formatMessage}
		/>
	);
};
