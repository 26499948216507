import { useEffect, useState, useLayoutEffect, useMemo } from 'react';
// eslint-disable-next-line jira/wrm/no-load-bridge
import { loadBridge } from '@atlassian/jira-common-bridge/src';
import {
	ROUTE_NAMES_RAPIDBOARD_BOARD,
	ROUTE_NAMES_RAPIDBOARD_BACKLOG,
} from '@atlassian/jira-common-constants/src/spa-routes';
import { ff } from '@atlassian/jira-feature-flagging';
import ecClient from '@atlassian/jira-jsis-ec-client/src/services/index.tsx';
import IssueMutation from '@atlassian/jira-jsis-ec-client/src/services/issue-mutation/index.tsx';
import { MutationSource } from '@atlassian/jira-jsis-ec-client/src/services/storage/constants.tsx';
import { useContainerContext } from '@atlassian/jira-providers-container-context/src/main.tsx';
import { boardConfigApiResource } from '@atlassian/jira-router-resources-classic-projects/src/services/config-api/index.tsx';
import { getRapidBoardDataResource } from '@atlassian/jira-router-resources-classic-projects/src/services/main.tsx';
import { rapidboardResource } from '@atlassian/jira-router-resources-classic-projects/src/services/rapidboard-resource/index.tsx';
import type { ReactRouterCompatibleHistory } from '@atlassian/jira-spa-router-adapters/src/common/types.tsx';
import { REPORT, WORK, CONFIG_DATA } from '../../constants';
import { isAllDataErrorValid } from '../../utils';
import { useRapidBoardContentLoadListener } from '../rapidboard-content-listener';
import { useRapidBoardResource } from '../resources';
import { useUrlState } from '../url-state';
import { useWrmBundleLoader } from '../wrm-bundle-state';
import { useCalendarSideEffects } from './utils/calendar';
import { cleanupDOM, cleanupGH, hideGhControllers, cleanupResources } from './utils/clean-up';
import { addEventListenersEcClient } from './utils/ec-client-listener';
import {
	resetWorkControllerFilters,
	setUpWrmData,
	setUpHistory,
	initDOM,
	renderRapidboardDom,
	afterRenderSetup,
	setupConfigResources,
} from './utils/set-up';

type RapidboardApps = {
	startApps: () => void;
};

let resolveInstallAppsPromise: (
	result: Promise<{ startApps: () => void }> | { startApps: () => void },
) => void;
const installAppsPromise: Promise<RapidboardApps> = new Promise((resolve) => {
	resolveInstallAppsPromise = resolve;
});

/**
 * We import from /entry because we cannot move the app install logic while rapidboard lives inside and outside the SPA
 */
(() => {
	import(
		/* webpackChunkName: "async-rapid-board-apps" */ '@atlassian/jira-entry-rapid-board-wrm-apps'
	).then((rapidBoardApps) => {
		resolveInstallAppsPromise(rapidBoardApps);
	});
})();

const startApps = () => {
	installAppsPromise.then((rapidBoardApps) => rapidBoardApps.startApps());
};
const useConfigResource = (boardId: string | number) => {
	const { promise, refresh, loading, update, data } = useRapidBoardResource({
		resource: boardConfigApiResource,
		shouldShowFlag: false,
	});
	const key = `rapidViewConfig-${boardId}`;
	return useMemo(
		() => ({
			key,
			data,
			promise,
			refresh,
			update,
			loading,
		}),
		[key, data, promise, refresh, update, loading],
	);
};

// @ts-expect-error - TS7023 - 'scopeObject' implicitly has return type 'any' because it does not have a return type annotation and is referenced directly or indirectly in one of its return expressions.
function scopeObject(val) {
	if (typeof val !== 'object') return val;
	return scopeObject(Object.values(val)[0]);
}

function extractIssueData(
	key: string | number,
	// @ts-expect-error - TS7005 - Variable 'value' implicitly has an 'any' type.
	value,
	issueVal: Object,
) {
	const res: Object = { ...issueVal };
	if (typeof value === 'string' || typeof value === 'number') {
		// @ts-expect-error - TS2339 - Property 'key' does not exist on type 'object'.
		res[key] = value;
		if (key === 'id' && typeof value === 'string') {
			// @ts-expect-error - TS2339 - Property 'key' does not exist on type 'object'.
			res[key] = parseInt(value, 10);
		}
		return res;
	}

	if (Array.isArray(value)) {
		value.forEach((field) => {
			const storeKey: string | number = field.id;
			const storeVal = scopeObject(field.fieldValue);
			// @ts-expect-error - TS2339 - Property 'storeKey' does not exist on type 'object'.
			res[storeKey] = storeVal;
			// @ts-expect-error - TS2339 - Property 'key' does not exist on type 'object'.
			if (storeKey === 'issuekey') res.key = storeVal;
		});
	}
	return res;
}

function decorateResponse(matchAPIIssueResponse: Object) {
	const issueResponse: Object = {};
	Object.entries(matchAPIIssueResponse).forEach(([key, value]) => {
		const issueId: string | number = key;
		const issueData: Object = value.issue;
		let issueVal = {};

		Object.entries(issueData).forEach(([issueDataKey, issueDataValue]) => {
			issueVal = extractIssueData(issueDataKey, issueDataValue, issueVal);
		});
		// @ts-expect-error - TS2339 - Property 'issueId' does not exist on type 'object'.
		issueResponse[issueId] = issueVal;
	});
	return issueResponse;
}

// TODO : Change this logic after JVIS fallback is introduced
function overwriteMatchData(searchData: Object, matchData: Object): Object {
	const result: Object = searchData;
	Object.keys(result).forEach((key) => {
		// @ts-expect-error - TS2339 - Property 'key' does not exist on type 'object'.
		if (typeof result[key] === 'object')
			// @ts-expect-error - TS2339 - Property 'key' does not exist on type 'object'.
			result[key] = overwriteMatchData(searchData[key], matchData);
		// @ts-expect-error - TS2339 - Property 'key' does not exist on type 'object'.
		else if (Object.prototype.hasOwnProperty.call(matchData, key)) result[key] = matchData[key];
	});
	return result;
}

export const useRapidBoardSetupAndTearDown = () => {
	const [hasLayoutLoaded, setHasLayoutLoaded] = useState(false);
	const { data: rapidboardData, loading: rapidboardResourceLoading } = useRapidBoardResource({
		resource: rapidboardResource,
	});
	const [{ data: projectData }] = useContainerContext();

	// @ts-expect-error - TS2571 - Object is of type 'unknown'.
	const isBentoOn = !!rapidboardData?.isNewIssueViewEnabled;
	const hasProjectDataLoaded = !!projectData;
	// @ts-expect-error - TS2339 - Property 'project' does not exist on type 'ContainerContext'. | TS2339 - Property 'project' does not exist on type 'ContainerContext'.
	const projectKey = projectData && projectData.project && projectData.project.key;
	// @ts-expect-error - TS2339 - Property 'project' does not exist on type 'ContainerContext'. | TS2339 - Property 'project' does not exist on type 'ContainerContext'.
	const projectId = projectData && projectData.project && projectData.project.id;

	useLayoutEffect(
		() => () => {
			cleanupGH(window, document)();
		},
		[],
	);

	useLayoutEffect(() => {
		let metaTags: Array<HTMLElement> = [];
		let initalClassList = ['adg3'];
		if (!rapidboardResourceLoading && hasProjectDataLoaded) {
			initalClassList = Array.from(document.body ? document.body.classList : []);
			metaTags = initDOM(isBentoOn, projectKey, String(projectId));
			setHasLayoutLoaded(true);
		}
		return () => {
			setHasLayoutLoaded(false);
			ff('jsw.classic.board.api-calls.modern-frontend') && cleanupResources([CONFIG_DATA]);
			cleanupDOM(document)(initalClassList, metaTags, isBentoOn);
		};
	}, [rapidboardResourceLoading, hasProjectDataLoaded, isBentoOn, projectId, projectKey]);

	useEffect(
		() => () => {
			!rapidboardResourceLoading && hideGhControllers();
		},
		[rapidboardResourceLoading],
	);

	return [hasLayoutLoaded, isBentoOn] as const;
};

const handleBackToBackNavigation = (mode: string) => {
	const { GH } = window;
	if (
		ff('jsw.classic.board.api-calls.modern-frontend') &&
		GH.RapidBoard.State?.isFirstLoad() === false
	) {
		GH.RapidBoard.State.setMode(mode);
	}
};

export const useRapidBoard = (history: ReactRouterCompatibleHistory) => {
	const [pastRapidView, setPastRapidView] = useState<string | null | undefined>(null);
	const [pastChartView, setPastChartView] = useState<string | undefined>(undefined);
	const [areAppsInstalled, setAreAppsInstalled] = useState<boolean | null>(null);
	const [isRapidBoardsReady, setIsRapidBoardsReady] = useState(false);
	const [shouldDeferToMonolith, setShouldDeferToMonolith] = useState(false);

	const { accountId, mode, boardId, chartView, projectKey, routeName, isBoardLocationless } =
		useUrlState();
	const [hasLayoutLoaded, isBentoEnabled] = useRapidBoardSetupAndTearDown();
	const [isAppContentLoaded, setIsAppContentLoaded] = useRapidBoardContentLoadListener();
	useCalendarSideEffects();

	const { hasGHContextLoaded } = useWrmBundleLoader();

	const {
		promise: allDataPromise,
		data,
		loading: allDataLoading,
		error: allDataError,
		hasFinishedRetrying: allDataAttemptFinished,
	} = useRapidBoardResource({
		resource: getRapidBoardDataResource(),
		retryPredicate: isAllDataErrorValid,
	});

	// @ts-expect-error - TS2339 - Property 'data' does not exist on type 'unknown'. | TS2339 - Property 'route' does not exist on type 'unknown'.
	const { data: allData, route: initialRoute } = data ?? {};

	const shouldUserSeeBoard = !(
		(allDataError &&
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			(allDataError as any).statusCode != null &&
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			(allDataError as any).statusCode === 404) ||
		(allData && allData.errors && allData.errors.rapidViewId)
	);

	let haveResourcesLoaded = !!(
		hasGHContextLoaded &&
		hasLayoutLoaded &&
		(allDataPromise || !allDataLoading)
	);

	const isReadyForMatching = !!(hasGHContextLoaded && (allDataPromise || !allDataLoading));
	let configResource: ReturnType<typeof useConfigResource>;

	if (ff('jsw.classic.board.api-calls.modern-frontend')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		configResource = useConfigResource(boardId == null ? '' : boardId);

		haveResourcesLoaded = !!(
			hasGHContextLoaded &&
			hasLayoutLoaded &&
			(allDataPromise || !allDataLoading) &&
			(configResource.promise || !configResource.loading)
		);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const parseIssueData = (issueData: any): any[] => {
		if (!issueData) return [];
		return issueData.issues;
	};

	function reconcilingData() {
		if (!allDataPromise || !boardId) return;

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		(allDataPromise as Promise<Object>).then(async (dataPromise: Object) => {
			const { reconciledIssues, matchAPIResponse } = await ecClient.searchAndReconcile(
				`/rest/greenhopper/1.0/xboard/issue/matches/board?rapidViewId=${boardId}`,
				boardId,
				// @ts-expect-error - TS2339 - Property 'data' does not exist on type 'object'.
				dataPromise.data,
				parseIssueData,
				'CLASSIC_BACKLOG',
			);

			let lastIssue: Object = {
				id: 0,
				key: '',
				hidden: false,
				typeName: '',
				typeId: '',
				typeHierarchyLevel: 0,
				summary: '',
				typeUrl: '',
				priorityUrl: '',
				priorityName: '',
				done: false,
				hasCustomUserAvatar: false,
				flagged: false,
				estimateStatisticRequired: false,
				estimateStatistic: {
					statFieldId: '',
					statFieldValue: {
						text: '',
					},
				},
				statusId: '',
				statusName: '',
				statusUrl: '',
				status: {
					id: '',
					name: '',
					description: '',
					iconUrl: '',
					statusCategory: {
						id: '',
						key: '',
						colorName: '',
					},
				},
				fixVersions: [],
				projectId: 0,
				linkedPagesCount: 0,
			};

			// @ts-expect-error - TS7005 - Variable 'rapidboardData' implicitly has an 'any' type.
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			(dataPromise.data as Promise<any>).then((rapidboardData) => {
				const matchedData = decorateResponse(matchAPIResponse.issue.issuesResponse);
				const remainingIssue = new Set();

				reconciledIssues.forEach((issueObj: Object, idx: number) => {
					// @ts-expect-error - TS2339 - Property 'id' does not exist on type 'object'.
					if (issueObj.id in matchedData) {
						// @ts-expect-error - TS2339 - Property 'id' does not exist on type 'object'.
						remainingIssue.add(issueObj.id);
						const merged = { ...lastIssue, ...reconciledIssues[idx] };
						reconciledIssues[idx] = overwriteMatchData(
							merged,
							// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{}'.
							matchedData[issueObj.id],
						);
					}
				});

				Object.entries(matchedData).forEach(([key, value]) => {
					if (!remainingIssue.has(parseInt(key, 10))) {
						lastIssue = { ...lastIssue };
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						const overwriteData = overwriteMatchData(lastIssue, value as Object);
						if (data) reconciledIssues.push(overwriteData);
						remainingIssue.add(parseInt(key, 10));
					}
				});

				const newRapidBoardData = rapidboardData;
				const { GH } = window;
				if (routeName === ROUTE_NAMES_RAPIDBOARD_BOARD && reconciledIssues.length > 0) {
					newRapidBoardData.issuesData.issues = reconciledIssues;
					GH.WorkController.setPoolData(newRapidBoardData);
				}
				if (routeName === ROUTE_NAMES_RAPIDBOARD_BACKLOG && reconciledIssues.length > 0) {
					newRapidBoardData.issues = reconciledIssues;
					GH.BacklogController.processData(false, newRapidBoardData);
				}
			});
		});
	}

	useEffect(() => {
		const { GH } = window;
		const hasUserSwitchedRouteDuringLoad =
			hasGHContextLoaded &&
			initialRoute &&
			initialRoute !== routeName &&
			GH?.RapidBoard?.State?.isFirstLoad();

		if (hasUserSwitchedRouteDuringLoad && isRapidBoardsReady) {
			GH?.RapidBoard?.State?.changeFirstLoadState?.();
			GH?.RapidBoard?.loadNewBoard?.(boardId);
		}
	}, [routeName, isRapidBoardsReady, boardId, initialRoute, hasGHContextLoaded]);

	useEffect(() => {
		const { GH } = window;
		// @ts-expect-error - TS2339 - Property 'statusCode' does not exist on type 'Error | Record<string, any> | (Error & Record<string, any>) | (Record<string, any> & Error)'.
		if (hasGHContextLoaded && allDataAttemptFinished && allDataError?.statusCode === 500) {
			// Fallback to let monolith handle error as it may be a board config issue
			GH.RapidBoard.State.changeFirstLoadState();
			setShouldDeferToMonolith(true);
		}
	}, [allDataAttemptFinished, allDataError, hasGHContextLoaded]);

	addEventListenersEcClient(isRapidBoardsReady, hasGHContextLoaded, routeName);

	useEffect(() => {
		if (
			ff('jsw.odin.ec.client.enable.reconciliation.iic') &&
			allDataPromise !== null &&
			isReadyForMatching
		) {
			reconcilingData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [routeName, boardId, allDataPromise, isReadyForMatching]);

	useEffect(() => {
		if (
			shouldDeferToMonolith ||
			(haveResourcesLoaded && (allData || mode === REPORT || pastRapidView))
		) {
			const { GH, AJS } = window;
			const dataPromise = new Promise((r: (result: Promise<never>) => void) => r(allData));

			if (mode !== REPORT && allData) {
				setUpWrmData(dataPromise);
			}
			if (ff('jsw.classic.board.api-calls.modern-frontend')) {
				const { key: configKey, promise: configPromise, update: configUpdate } = configResource;
				setupConfigResources(configPromise, configKey, configUpdate);
			}
			setUpHistory(history, {
				accountId,
				mode,
				boardId,
				rapidView: boardId,
				chartView,
				projectKey,
				isBoardLocationless,
			});

			if (
				pastRapidView === boardId &&
				pastChartView === chartView &&
				GH?.RapidBoard?.State?.getMode?.() === mode
			) {
				return;
			}

			if (
				pastRapidView == null &&
				window.GH?.RapidBoard &&
				String(window.GH.RapidBoard?.State?.data?.rapidViewId) === boardId
			) {
				// Render initial content for rapidboards to mount onto
				renderRapidboardDom(mode === WORK);
				GH.RapidBoard.ViewController.setMode(mode, true);
				afterRenderSetup();

				setIsRapidBoardsReady(true);
				setPastRapidView(boardId);
				return;
			}

			if (pastRapidView === boardId) {
				if (
					isAppContentLoaded &&
					isRapidBoardsReady &&
					GH.RapidBoard.State.getDataValue('mode') &&
					mode !== GH.RapidBoard.State.getMode()
				) {
					GH.RapidBoard.ViewController.setMode(mode);
				}
				if (pastChartView !== chartView) {
					setIsAppContentLoaded(false);
					GH.RapidBoard.init();
					setPastChartView(chartView);
				}
			} else {
				resetWorkControllerFilters();
				setIsRapidBoardsReady(false);
				setIsAppContentLoaded(false);
				handleBackToBackNavigation(mode);
				GH.RapidBoard.init().then(() => {
					setIsRapidBoardsReady(true);
					!areAppsInstalled && startApps();
					setAreAppsInstalled(true);
					setShouldDeferToMonolith(false);

					if (mode === REPORT && AJS) {
						AJS.$(window).trigger('resize.rapidboardSpa');
					}

					afterRenderSetup();
				});
				setPastRapidView(boardId);
				setPastChartView(chartView);
			}
		}
	}, [
		haveResourcesLoaded,
		allData,
		mode,
		history,
		areAppsInstalled,
		pastRapidView,
		boardId,
		pastChartView,
		chartView,
		isRapidBoardsReady,
		projectKey,
		isAppContentLoaded,
		setIsAppContentLoaded,
		shouldDeferToMonolith,
		accountId,
		// @ts-expect-error - Variable 'configResource' is used before being assigned
		configResource,
		isBoardLocationless,
	]);

	// @ts-expect-error - TS7005 - Variable 'event' implicitly has an 'any' type.
	function IICFire(event, issue: Object) {
		if (ff('jsw.odin.ec.client.enable.reconciliation.iic')) {
			return ecClient
				.saveIssueMutationToCache(
					// @ts-expect-error - TS2339 - Property 'issueId' does not exist on type 'object'.
					new IssueMutation(issue.issueId, MutationSource.CREATE, '1', [], Date.now()),
				)
				.then((result) => {
					if (!result)
						window.AJS.trigger('analyticsEvent', {
							name: 'jira-frontend.classic-software.controllers.rapidboard-state.inline-issue-create-listener.unable.to.save.error',
							data: { errorMsg: 'Failed at IIC save event' },
						});
					// TODO : It is causing flickering UI bug ODIN-90
					reconcilingData();
				});
		}
	}

	useEffect(() => {
		// eslint-disable-next-line jira/wrm/no-load-bridge
		loadBridge({
			name: 'jira/util/events',
			wrmKeys: 'wrc!jira.webresources:jira-events',
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
		}).then((Events) => Events.bind('inlineIssueCreatedOnBacklog', IICFire));
		return () => {
			// eslint-disable-next-line jira/wrm/no-load-bridge
			loadBridge({
				name: 'jira/util/events',
				wrmKeys: 'wrc!jira.webresources:jira-events',
				// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			}).then((Events) => Events.unbind('inlineIssueCreatedOnBacklog', IICFire));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		isRapidBoardsReady,
		mode,
		isBentoEnabled: !!isBentoEnabled,
		shouldUserSeeBoard,
	};
};
