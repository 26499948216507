import createEvent from '@atlassian/jira-common-util-create-event';
import { CLEAR_SEARCH_SESSION } from '@atlassian/jira-session-tracker/src/view/constants';
import { WORK, PLAN, REPORT, GH_SPA_CONTENT_READY, GH_SPA_CONTENT_LOADING } from '../../constants';

const defaultState = {
	[WORK]: false,
	[PLAN]: false,
	[REPORT]: false,
} as const;

export const initialState = {
	ttr: {
		...defaultState,
	},
	tti: {
		...defaultState,
	},
} as const;

type CustomContentLoadedEvent = Event & {
	mode?: string;
};
type SetHasContentLoaded = (arg1: typeof initialState) => void;
type SetAppReady = () => void;

export const generateContentLoadingListener = (setHasContentLoaded: SetHasContentLoaded) =>
	function ttrListener(ev: CustomContentLoadedEvent) {
		setHasContentLoaded({
			ttr: {
				...defaultState,
				// @ts-expect-error - A computed property name must be of type 'string', 'number', 'symbol', or 'any'.
				[ev.mode]: true,
			},
			tti: {
				...defaultState,
			},
		});
		window.removeEventListener(GH_SPA_CONTENT_LOADING, ttrListener);
	};

export const generateContentDoneListener = (
	setHasContentLoaded: SetHasContentLoaded,
	setAppReady: SetAppReady,
) =>
	function ttiListener(ev: CustomContentLoadedEvent) {
		setHasContentLoaded({
			ttr: {
				...defaultState,
			},
			tti: {
				...defaultState,
				// @ts-expect-error - A computed property name must be of type 'string', 'number', 'symbol', or 'any'.
				[ev.mode]: true,
			},
		});
		setAppReady();
		window.dispatchEvent(createEvent(CLEAR_SEARCH_SESSION));
		window.removeEventListener(GH_SPA_CONTENT_READY, ttiListener);
	};
