import React, { useEffect, useMemo, type ReactNode } from 'react';
import { render } from 'react-dom';
import '@atlaskit/css-reset';
import { Text } from '@atlaskit/primitives';
// eslint-disable-next-line jira/wrm/no-load-bridge
import { loadBridgeWeak } from '@atlassian/jira-common-bridge/src';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types';
import { standardizeLocale } from '@atlassian/jira-common-constants/src/supported-locales';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import { IntlProvider } from '@atlassian/jira-intl/src/intl-provider.tsx';
import { useJiraServiceManagementProjectNavigation } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/project-sidebar-navigation-resource/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import { getNextgenSettingsIssueTypesUrl } from '@atlassian/jira-project-settings-apps-common/src/urls';
import RedirectIfProjectArchived from '@atlassian/jira-redirect-archived-project/src/ui/index.tsx';
import { useResource } from '@atlassian/jira-router';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { useServiceDeskAnalyticsContext } from '@atlassian/jira-router-resources-service-desk-analytics-context/src/services/index.tsx';
import { uifBoardResource } from '@atlassian/jira-router-resources-uif-board';
import { APP_NAMES } from '@atlassian/jira-servicedesk-common/src/utils/app-names';
import ServiceDeskAppBase from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/app-base/index.tsx';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { NextGenBoardSkeleton } from '@atlassian/jira-skeletons/src/ui/next-gen-board/index.tsx';
import { SuspenseTrackerFallback } from '@atlassian/jira-software-suspense-tracker';
import type { UIFBoardCachedDataResult } from '@atlassian/jira-software-uif-early-script';
import RapidBoard from '@atlassian/jira-spa-apps-rapid-board';
import { MarkProductStart } from '@atlassian/jira-spa-performance-breakdown/src/utils/mark-product-start/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';
import { usePathParam } from '@atlassian/react-resource-router';
import { WORKFLOWS_LINK_TEST_ID } from './constants';
import messages from './messages';
import type { ServiceDeskBoardConfigBridgeApi } from './types';

const ColumnsConfigMappingPageDescription = ({
	projectKey,
	isSimplified,
}: {
	projectKey: ProjectKey;
	isSimplified: boolean;
}) => {
	const { message, url } = useMemo(
		() =>
			isSimplified
				? {
						message: messages.columnConfigDescriptionTmp,
						url: getNextgenSettingsIssueTypesUrl(SERVICE_DESK_PROJECT, projectKey),
					}
				: {
						message: messages.columnConfigDescriptionCmp,
						url: `/plugins/servlet/project-config/${projectKey}/workflows`,
					},
		[isSimplified, projectKey],
	);

	return (
		<FormattedMessage
			{...message}
			values={{
				// @ts-expect-error - Type '(content: string) => JSX.Element' is not assignable to type 'ReactNode'.
				a: (content: string) => (
					<a data-testid={WORKFLOWS_LINK_TEST_ID} href={url}>
						{content}
					</a>
				),
			}}
		/>
	);
};

const ColumnConstraintGroupInstructions = () => {
	const { formatMessage } = useIntl();
	return <Text>{formatMessage(messages.columnConstraintGroupInstructions)}</Text>;
};

type ServiceDeskBoardConfigBridge = (api: ServiceDeskBoardConfigBridgeApi) => void;

const getBoardSettingsTabs = (isBoardEnabled: boolean, isCalendarEnabled: boolean) => {
	const dateFieldsTab =
		ff('jsm-board-settings-date-fields-tab_d75f4') && isCalendarEnabled ? ['dateFields'] : [];

	if (ff('jsm-calendar-ga_81o9m') && !isBoardEnabled) {
		return ['filter', ...dateFieldsTab];
	}

	return [
		'columns',
		'filter',
		'quickFilters',
		'swimlanes',
		'cardColors',
		'cardLayout',
		...dateFieldsTab,
	];
};

const createApi = ({
	locale,
	projectKey,
	isSimplified,
	analyticsContextAttributes,
	isBoardEnabled,
	isCalendarEnabled,
}: {
	locale: string;
	projectKey: ProjectKey;
	isSimplified: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	analyticsContextAttributes: any;
	isBoardEnabled: boolean;
	isCalendarEnabled: boolean;
}): ServiceDeskBoardConfigBridgeApi => {
	const renderComponent = ({
		children,
		container,
	}: {
		children: ReactNode;
		container: HTMLElement | null;
	}) =>
		render(<IntlProvider locale={standardizeLocale(locale)}>{children}</IntlProvider>, container);

	return {
		renderConfigEditScreen: () => {
			const configNav = document.getElementById('ghx-config-nav');
			if (configNav) {
				const tabs = getBoardSettingsTabs(isBoardEnabled, isCalendarEnabled);
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
				for (const child of (configNav as any).children) {
					if (!tabs.includes(child.getAttribute('data-tabitem'))) {
						child.style.display = 'none';
					}
				}
			}
		},
		renderColumnMapping: () => {
			const configColumnsDescription = document.querySelector<HTMLElement>(
				'#ghx-config-columns-description',
			);
			if (document.body && document.body.contains(configColumnsDescription)) {
				renderComponent({
					children: (
						<ColumnsConfigMappingPageDescription
							projectKey={projectKey}
							isSimplified={isSimplified}
						/>
					),
					container: configColumnsDescription,
				});
			}

			const columnsConfigForm = document.getElementById('ghx-config-columns-form');
			if (columnsConfigForm) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
				for (const child of (columnsConfigForm as any).children) {
					if (child.id !== 'ghx-config-columns-column-constraint-group') {
						child.style.display = 'none';
					}
				}
			}

			const columnConstraintDescriptionContainer = document.querySelector<HTMLElement>(
				'#ghx-config-columns-column-constraint-group div.description',
			);
			if (document.body && document.body.contains(columnConstraintDescriptionContainer)) {
				renderComponent({
					children: <ColumnConstraintGroupInstructions />,
					container: columnConstraintDescriptionContainer,
				});
			}

			const backlogColumn = document.querySelector<HTMLElement>(
				'#ghx-mapping-columns div.ghx-kanplan-backlog-column',
			);
			if (backlogColumn) {
				backlogColumn.style.display = 'none';
			}
		},
		getAnalyticContextAttributes: () => analyticsContextAttributes,
	};
};

const useApi = ({
	projectKey,
	isSimplified,
	analyticsContextAttributes,
}: {
	projectKey: ProjectKey;
	isSimplified: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	analyticsContextAttributes: any;
}) => {
	const { locale } = useIntl();

	const { data } = ff('jsm-calendar-ga_81o9m')
		? // remove the eslint disable code when clean up ff 'jsm-calendar-ga_81o9m'
			// eslint-disable-next-line react-hooks/rules-of-hooks
			useJiraServiceManagementProjectNavigation()
		: { data: null };

	const isBoardEnabled = !!data?.boardInfo.enabled;

	// @TODO: Remove the optionality when cleaning up 'jsm-calendar-view_bplvu'
	// src/packages/navigation-apps/resources/src/services/sidebar/project-sidebar-navigation/types.tsx#140
	const isCalendarEnabled = !!data?.calendarInfo?.enabled;

	return useMemo(
		() =>
			createApi({
				locale,
				projectKey,
				isSimplified,
				analyticsContextAttributes,
				isBoardEnabled,
				isCalendarEnabled,
			}),
		[
			locale,
			projectKey,
			isSimplified,
			analyticsContextAttributes,
			isBoardEnabled,
			isCalendarEnabled,
		],
	);
};

const ServicedeskLegacyBoardSettingsApp = ({
	projectKey,
	isSimplified,
	analyticsContextAttributes,
}: {
	projectKey: ProjectKey;
	isSimplified: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	analyticsContextAttributes: any;
}) => {
	const api = useApi({ projectKey, isSimplified, analyticsContextAttributes });

	useEffect(() => {
		// eslint-disable-next-line jira/wrm/no-load-bridge
		loadBridgeWeak<ServiceDeskBoardConfigBridge>({
			name: 'jira-servicedesk/boards/configuration/bridge',
		}).then((bridge: ServiceDeskBoardConfigBridge) => {
			bridge(api);
		});
	}, [api]);

	return (
		<>
			<RapidBoard />
			<RedirectIfProjectArchived projectKey={projectKey} />
		</>
	);
};

export const ServicedeskLegacyBoardSettingsAppLayout = () => {
	const [paramProjectKey] = usePathParam('projectKey');
	const cmpBoardData = useResource<UIFBoardCachedDataResult | null>(uifBoardResource);
	const { key } = cmpBoardData.data?.result?.boardLocation ?? {};
	const projectKey = key ?? paramProjectKey ?? null;
	const projectContext = useResource(projectContextResource);
	const projectId = projectContext?.data?.projectId;
	const simplified = projectContext?.data?.simplified;
	const { data: serviceDeskAnalyticsAttributes } = useServiceDeskAnalyticsContext();

	const analyticsWithAdditionalAttributes = useMemo(
		() => ({
			...serviceDeskAnalyticsAttributes,
			projectId,
		}),
		[serviceDeskAnalyticsAttributes, projectId],
	);

	const analyticsContextAttributes = ff(
		'jsm-analytic-attributes-and-project-id-board-screen-events_zowwe',
	)
		? analyticsWithAdditionalAttributes
		: serviceDeskAnalyticsAttributes;

	if (!projectKey || simplified == null) {
		return null;
	}

	return (
		<UFOSegment name="servicedesk-legacy-board-settings">
			<Placeholder
				fallback={
					<>
						<NextGenBoardSkeleton />
						<SuspenseTrackerFallback />
					</>
				}
				name="servicedesk-legacy-board-settings"
			>
				<MarkProductStart />
				<ServiceDeskAppBase appName={APP_NAMES.BOARD_SETTINGS}>
					<ErrorBoundary
						id="spa-apps-servicedesk.legacy-board-settings"
						packageName="spa-apps-servicedesk-legacy-board-settings"
					>
						<ServicedeskLegacyBoardSettingsApp
							projectKey={projectKey}
							isSimplified={simplified}
							analyticsContextAttributes={analyticsContextAttributes}
						/>
					</ErrorBoundary>
				</ServiceDeskAppBase>
			</Placeholder>
		</UFOSegment>
	);
};
