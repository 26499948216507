import { SUCCESS, FAILURE } from '../../constants';
import { isPlanningMode, isWorkMode } from '../../utils';
import type { SLAResponse } from './types';

const isBoardLoadComplete = (rapidBoard: HTMLElement | null): boolean => {
	if (rapidBoard === null) {
		return false;
	}
	const columns = rapidBoard.querySelectorAll('.ghx-swimlane.ghx-first .ghx-columns .ghx-column');
	const issues = rapidBoard.querySelectorAll('.ghx-issue');
	const emptyColumns = rapidBoard.querySelectorAll('.ghx-sad-columns .ghx-column');
	const emptyBoard = rapidBoard.querySelectorAll('.ghx-no-active-sprint-message');
	const { GH } = window;
	const filters = GH && GH.WorkController?.activeFilters;
	const haveFiltersApplied =
		(filters && filters?.assigneeList && filters.assigneeList.length > 0) ||
		(filters?.issueTypeList && filters.issueTypeList.length > 0) ||
		(filters?.searchText && filters.searchText.length > 0);
	const haveQuickFiltersApplied =
		GH?.WorkControls?.quickfilters &&
		// @ts-expect-error - TS7006 - Parameter 'filter' implicitly has an 'any' type.
		GH.WorkControls.quickfilters.getQuickFilters().filter((filter) => filter.isActive).length > 0;
	return (
		emptyBoard.length === 1 ||
		(columns.length > 0 && (issues.length > 0 || haveFiltersApplied || haveQuickFiltersApplied)) ||
		(emptyColumns.length > 0 && issues.length === 0)
	);
};

const isBackLogLoadComplete = (rapidBoard: HTMLElement | null): boolean => {
	if (rapidBoard === null) {
		return false;
	}
	const issues = rapidBoard.querySelectorAll('.ghx-backlog-card');
	const emptyBacklogMessage = rapidBoard.querySelectorAll('.ghx-onboarding-fillyourbacklog-group');
	const backlogHeader = rapidBoard.querySelectorAll('.ghx-backlog-header.js-marker-backlog-header');
	return (
		issues.length > 0 ||
		emptyBacklogMessage.length > 0 ||
		(issues.length === 0 && backlogHeader && backlogHeader.length >= 1)
	);
};

export const getSLAResult = (statusCode: number | null, mode: string): SLAResponse => {
	// Used in Legacy Code. Caution in modifying the params is required.
	const { GH } = window;
	if (GH !== null && GH !== undefined) {
		if (
			(isWorkMode(mode) && isBoardLoadComplete(document.getElementById('ghx-work'))) ||
			(isPlanningMode(mode) && isBackLogLoadComplete(document.getElementById('ghx-backlog')))
		) {
			return {
				state: SUCCESS,
				statusCode,
				href: window.location.href,
			};
		}
	}
	if (statusCode !== null) {
		if (statusCode >= 400 && statusCode < 500) {
			return {
				state: SUCCESS,
				statusCode,
				href: window.location.href,
			};
		}
	}
	return {
		state: FAILURE,
		statusCode,
		href: window.location.href,
	};
};
