import { useEffect } from 'react';
import { GIC_LIFECYCLE_EVENTS } from '@atlassian/jira-issue-create-extensibility/src/common/utils/lifecycle-events/constants.tsx';
import { useGICLifeCycleEvents } from '@atlassian/jira-issue-create-extensibility/src/common/utils/lifecycle-events/main.tsx';
import legacyBoardEvents from '@atlassian/jira-issue-view-common/src/legacy/board-event';
import { useTriggerIssueCreateModal } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/main.tsx';

export const useGICSidebarForcer = () => {
	const [{ minimizableModalView, isModalOpen }] = useTriggerIssueCreateModal();
	const { subscribe } = useGICLifeCycleEvents();

	/*
    Force issue view into sidebar when gic modal is docked or minimized.
    In the case where a gic modal is opened inside a issue view modal, we prevent forcing the issue view into sidebar as this causes weird visual artifacts.
    This is the only scenario where the issue view and gic modal are opened both simultaneously in modal state.
    */
	useEffect(() => {
		if (isModalOpen && (minimizableModalView === 'mini' || minimizableModalView === 'minimized')) {
			// Force CMP issue view into sidebar through monolith
			legacyBoardEvents.setIsGlobalIssueCreateOpen(true);
		}
	}, [isModalOpen, minimizableModalView]);

	useEffect(
		() =>
			subscribe(GIC_LIFECYCLE_EVENTS.MODAL_CLOSED, async () => {
				// Reset CMP issue-view's view mode to its previous state
				legacyBoardEvents.setIsGlobalIssueCreateOpen(false);
			}),
		[subscribe],
	);
};
