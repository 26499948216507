import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
// eslint-disable-next-line jira/wrm/no-load-bridge
import { loadBridge } from '@atlassian/jira-common-bridge/src';
import { EditSprintButtonApp } from '@atlassian/jira-edit-sprint-modal-legacy-bridge/src/ui/edit-sprint-button/index.tsx';

export type Props = {
	boardId: number;
	sprintId: string;
	locale: string;
	onSubmit: () => void;
};

type EditSprintModalBridgeApi = {
	render: (container: Element, props: Props) => void;
	unmount: (container: Element) => void;
};

type EditSprintModalBridge = {
	resolve: (api: EditSprintModalBridgeApi) => void;
};

export const initBridge = () => {
	const editSprintButtonBridge: EditSprintModalBridgeApi = {
		render: (domObj: Element, props: Props) => {
			if (document.body && document.body.contains(domObj)) {
				render(<EditSprintButtonApp {...props} />, domObj);
			}
		},
		unmount: (container: Element) => {
			if (document.body && document.body.contains(container)) {
				unmountComponentAtNode(container);
			}
		},
	};

	// eslint-disable-next-line jira/wrm/no-load-bridge
	loadBridge<EditSprintModalBridge>({
		name: 'jira-agile/rapid/ui/sprint-edit-modal-bridge',
	}).then((bridge) => bridge.resolve(editSprintButtonBridge));
};
