import { getRapidBoardDataResource } from '@atlassian/jira-router-resources-classic-projects/src/services/main.tsx';
import { isAllDataErrorValid } from '../../utils';
import { useRapidBoardResource } from '../resources';
import { useWrmBundleLoader } from '../wrm-bundle-state';

export const useSLAHandler = () => {
	const {
		loading: dataLoading,
		error: dataError,
		hasFinishedRetrying,
	} = useRapidBoardResource({
		resource: getRapidBoardDataResource(),
		shouldShowFlag: false,
		retryPredicate: isAllDataErrorValid,
	});
	const { errorGHContext } = useWrmBundleLoader();

	const isResourceOrApiLoading: boolean = dataLoading;

	// @ts-expect-error - TS2345 - Argument of type 'Error | Record<string, any> | (Error & Record<string, any>) | (Record<string, any> & Error) | null' is not assignable to parameter of type 'RouteResourceError'.
	const slaError = isAllDataErrorValid(dataError) ? dataError : null;

	return {
		slaFailure: !isResourceOrApiLoading ? slaError : null,
		dataError,
		wrmError: errorGHContext,
		hasFinishedRetrying,
	};
};
