import { useEffect, useState } from 'react';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { useRapidBoardContentListener } from '../../rapidboard-content-listener';

declare global {
	interface Window {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		moment: any;
	}
}

export const useCalendarSideEffects = () => {
	const [haveMonkeyPatchedStartSprint, setHaveMonkeyPatchedStartSprint] = useState(false);
	const locale = useLocale();
	const contentLoadedStatus = useRapidBoardContentListener();

	useEffect(() => {
		try {
			const { moment, Calendar, GH } = window;
			if (!moment || !Calendar) {
				return;
			}

			const langData = moment.localeData('jira');
			if (!langData) {
				return;
			}

			// Calendar.js needs 8 element array for days (starting and ending with sunday)
			Calendar._DN = langData._weekdays.concat(langData._weekdays[0]);
			Calendar._SDN = langData._weekdaysShort.concat(langData._weekdaysShort[0]);

			Calendar._MN = [].concat(langData._months);
			Calendar._SMN = [].concat(langData._monthsShort);

			if (
				GH?.SprintController?.openStartSprintDialog &&
				locale === 'ja_JP' &&
				!haveMonkeyPatchedStartSprint
			) {
				const originalFunction = GH.SprintController.openStartSprintDialog;
				// @ts-expect-error - TS7019 - Rest parameter 'args' implicitly has an 'any[]' type.
				GH.SprintController.openStartSprintDialog = (...args) => {
					Calendar._TT.AM = '\u5348\u524D';
					Calendar._TT.PM = '\u5348\u5F8C';
					Calendar._TT.am = '\u5348\u524D';
					Calendar._TT.pm = '\u5348\u5F8C';

					originalFunction(...args);
				};

				setHaveMonkeyPatchedStartSprint(true);
			}

			// eslint-disable-next-line @typescript-eslint/no-explicit-any, no-empty
		} catch (e: any) {}
	}, [haveMonkeyPatchedStartSprint, contentLoadedStatus, locale]);
};
