import {
	ROUTE_GROUPS_DIRECTORIES,
	ROUTE_GROUPS_ISSUE,
	ROUTE_NAMES_PROJECTS_DIRECTORY,
	ROUTE_NAMES_ISSUE,
	ROUTE_NAMES_RAPIDBOARD_BACKLOG,
	ROUTE_NAMES_RAPIDBOARD_USER_BACKLOG,
	ROUTE_NAMES_RAPIDBOARD_BOARD,
	ROUTE_NAMES_RAPIDBOARD_USER_BOARD,
	ROUTE_NAMES_RAPIDBOARD_REPORT,
	ROUTE_NAMES_RAPIDBOARD_USER_REPORT,
} from '@atlassian/jira-common-constants/src/spa-routes';
import log from '@atlassian/jira-common-util-logging/src/log';
import type { Query } from '@atlassian/jira-router';
import { getSpaStateLastUserLocation } from '@atlassian/jira-spa-state-controller/src/components/main.tsx';
import { classicBacklog, classicBoard, classicReport } from '../../utils/performance-analytics';

const LOCATION = 'spa.rapidboard';

// PREFETCH ONLY FOR ISSUE VIEW AND PROJECTS-DIRECTORY
// TO-DO: Add it in constant file
const PREFETCH_USER_LOCATIONS = new Set([
	`${ROUTE_GROUPS_DIRECTORIES}:${ROUTE_NAMES_PROJECTS_DIRECTORY}`,
	`${ROUTE_GROUPS_ISSUE}:${ROUTE_NAMES_ISSUE}`,
]);

// check if the apps loaded on last page contain any app on which prefetching was done.
const isPrefetchRoute = () => {
	const lastUserLocation = getSpaStateLastUserLocation();
	// @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
	return PREFETCH_USER_LOCATIONS.has(lastUserLocation);
};

export const getApdexAttributes = (query: Query) => {
	const { GH } = window;
	try {
		const { modal, selectedIssue, view, quickFilter = '', assignee = '' } = query;
		const defaultAttributes = {
			isDetailView:
				(modal === 'detail' || view === 'detail' || view === 'planning') && selectedIssue !== '',
			isQuickFilterView: quickFilter !== '' || assignee !== '',
			isPrefetchRoute: isPrefetchRoute(),
		};

		return GH && GH.RapidBoard && GH.RapidBoard.State
			? {
					...defaultAttributes,
					...GH.RapidBoard.State.getApdexAttributes(),
				}
			: defaultAttributes;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		log.safeWarnWithoutCustomerData(LOCATION, 'Error getting rapidboard apdex attributes', err);
		return {};
	}
};

export const getBM3Metric = (routeName: string) => {
	switch (routeName) {
		case ROUTE_NAMES_RAPIDBOARD_BACKLOG:
		case ROUTE_NAMES_RAPIDBOARD_USER_BACKLOG:
			return classicBacklog;
		case ROUTE_NAMES_RAPIDBOARD_BOARD:
		case ROUTE_NAMES_RAPIDBOARD_USER_BOARD:
			return classicBoard;
		case ROUTE_NAMES_RAPIDBOARD_REPORT:
		case ROUTE_NAMES_RAPIDBOARD_USER_REPORT:
			return classicReport;
		default:
			return undefined;
	}
};
