import React, { type ReactNode } from 'react';
import {
	LICENSE_STATE_ACTIVE,
	LICENSE_STATE_SUSPENDED,
} from '@atlassian/jira-shared-types/src/license-state.tsx';
import { AsyncNoSoftwareLicense } from '@atlassian/jira-spa-apps-common/src/error-screens/no-software-license/async';
import { AsyncSuspendedSoftwareLicense } from '@atlassian/jira-spa-apps-common/src/error-screens/suspended-software-license/async';
import { LicenseStateCheck } from '@atlassian/jira-spa-apps-common/src/interceptors/license-state-check';

type Props = {
	children: ReactNode;
};

export const LicenseCheck = ({ children }: Props) => (
	<LicenseStateCheck type="software">
		{/* @ts-expect-error - TS2322 - Type '({ licenseState }: { licenseState: ApplicationLicenseState; }) => ReactNode' is not assignable to type '(arg1: { licenseState: ApplicationLicenseState; }) => ReactElement<any, string | JSXElementConstructor<any>>'. */}
		{({ licenseState }) => {
			if (licenseState === LICENSE_STATE_ACTIVE) {
				return children;
			}

			if (licenseState === LICENSE_STATE_SUSPENDED) {
				return <AsyncSuspendedSoftwareLicense />;
			}

			return <AsyncNoSoftwareLicense />;
		}}
	</LicenseStateCheck>
);
