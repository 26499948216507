import { useEffect } from 'react';
import { getLongTasksMetrics } from '@atlassian/jira-common-long-task-metrics';
import { softwareGenericLongTasksReporter } from '../reporters/software-generic-long-tasks';

type RapidBoardMode = 'rapidboard-board' | 'rapidboard-backlog' | string;

/**
 * This hook adds custom event listeners to start/stop drag events fired from
 * the legacy rapid-boards front-end.
 *
 * When drag-and-drop is ongoing, we track long-tasks.
 */
export const useRapidBoardLongTasks = (mode: RapidBoardMode) => {
	useEffect(() => {
		if (mode !== 'rapidboard-board' && mode !== 'rapidboard-backlog') {
			return undefined;
		}

		// This is intentionally verbose to show in searches.
		const startEvent =
			mode === 'rapidboard-board'
				? 'jira:gh:work:drag-and-drop:start'
				: 'jira:gh:plan:drag-and-drop:start';
		const endEvent =
			mode === 'rapidboard-board'
				? 'jira:gh:work:drag-and-drop:stop'
				: 'jira:gh:plan:drag-and-drop:stop';

		const onDragStart = () => {
			getLongTasksMetrics('dnd').start(mode);
		};
		const onDragStop = () => {
			getLongTasksMetrics('dnd').stop(softwareGenericLongTasksReporter('dnd'));
		};

		window.addEventListener(startEvent, onDragStart);
		window.addEventListener(endEvent, onDragStop);

		return () => {
			window.removeEventListener(startEvent, onDragStart);
			window.removeEventListener(endEvent, onDragStop);
			getLongTasksMetrics('dnd').stop(softwareGenericLongTasksReporter('dnd'));
		};
	}, [mode]);
};
