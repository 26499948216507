import { useEffect, useRef } from 'react';
import noop from 'lodash/noop';
// eslint-disable-next-line jira/wrm/no-load-bridge
import { loadBridge } from '@atlassian/jira-common-bridge/src';
import { getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import { useIssueMutationEventsFactoryBacklogAndBoard } from '@atlassian/jira-jsis-ec-client/src/services/issue-mutation-events/backlog-and-board-issue-mutation-events/cbb-issue-mutations-events-components/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

/**
 * initialize an Event binding for Eventual consistency stream
 * to listen to issue create events using inline issue create on baclog
 */

export const addEventListenersEcClient = (
	isRapidBoardsReady: boolean,
	hasWrmLoaded: boolean,
	routeName: string,
) => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const experiences: string = getFeatureFlagValue('odin.ec.client.integration.cbb', '') as string;

	if (experiences.length === 0) return null;

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const subscriptionHandle = useRef<() => void>(noop);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const analyticsEventObj = createAnalyticsEvent({});

	const setMutationEvents =
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useIssueMutationEventsFactoryBacklogAndBoard({
			analyticsEventObj,
		});

	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(() => {
		if (isRapidBoardsReady && hasWrmLoaded) {
			// eslint-disable-next-line jira/wrm/no-load-bridge
			loadBridge({
				name: 'jira/util/events',
				wrmKeys: 'wrc!jira.webresources:jira-events',
			}).then((Events) => {
				const issueMutationEventsFactoryBacklogAndBoard = setMutationEvents(
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					Events as Object,
				);
				issueMutationEventsFactoryBacklogAndBoard.initialize();
				issueMutationEventsFactoryBacklogAndBoard.bind();

				subscriptionHandle.current = () => {
					issueMutationEventsFactoryBacklogAndBoard.unbind();
				};
			});
		}
		return () => {
			subscriptionHandle.current();
			subscriptionHandle.current = noop;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isRapidBoardsReady, hasWrmLoaded, routeName, subscriptionHandle]);

	return null;
};
