import { MutationSource } from '../../storage/constants';

export const eventsDataBacklogAndBoard = {
	ecClientIIC: {
		captureEventName: 'inlineIssueCreatedOnBacklog',
		mutationSource: MutationSource.CREATE,
		issueVersion: '1',
		matchStatus: [],
		timeOfMutation: new Date().toISOString(),
		eventName: 'jira-frontend.classic-software.controllers.rapidboard-state.issue.mutated',
		analyticsObject: {
			errorMsg: 'FAILED_IIC',
			error: true,
		},
	},
	ecClientGIC: {
		captureEventName: 'legacyIssueCreated',
		mutationSource: MutationSource.CREATE,
		issueVersion: '1',
		matchStatus: [],
		timeOfMutation: new Date().toISOString(),
		eventName: 'jira-frontend.classic-software.controllers.rapidboard-state.issue.mutated',
		analyticsObject: {
			errorMsg: 'FAILED_GIC',
			error: true,
		},
	},
	ecClientEpicIssueAdded: {
		captureEventName: 'issueAddedToEpic',
		mutationSource: MutationSource.UPDATE,
		issueVersion: '1',
		matchStatus: [],
		timeOfMutation: new Date().toISOString(),
		eventName: 'jira-frontend.classic-software.controllers.rapidboard-state.issue.mutated',
		analyticsObject: {
			errorMsg: 'FAILED_ISSUE_EPIC',
			error: true,
		},
	},
	ecClientVersionIssueAdded: {
		captureEventName: 'issueAddedToVersion',
		mutationSource: MutationSource.UPDATE,
		issueVersion: '1',
		matchStatus: [],
		timeOfMutation: new Date().toISOString(),
		eventName: 'jira-frontend.classic-software.controllers.rapidboard-state.issue.mutated',
		analyticsObject: {
			errorMsg: 'FAILED_ISSUE_VERSION',
			error: true,
		},
	},
	ecClientVersion: {
		captureEventName: 'versionModified',
		mutationSource: MutationSource.UPDATE,
		issueVersion: '1',
		matchStatus: [],
		timeOfMutation: new Date().toISOString(),
		eventName: 'jira-frontend.classic-software.controllers.rapidboard-state.issue.mutated',
		analyticsObject: {
			errorMsg: 'FAILED_VERSION',
			error: true,
		},
	},
	ecClientEpic: {
		captureEventName: 'epicChanged',
		mutationSource: MutationSource.UPDATE,
		issueVersion: '1',
		matchStatus: [],
		timeOfMutation: new Date().toISOString(),
		eventName: 'jira-frontend.classic-software.controllers.rapidboard-state.issue.mutated',
		analyticsObject: {
			errorMsg: 'FAILED_EPIC',
			error: true,
		},
	},
	ecClientSplitIssue: {
		captureEventName: 'issueSplit',
		mutationSource: { originalIssue: MutationSource.UPDATE, newIssues: MutationSource.CREATE },
		issueVersion: '1',
		matchStatus: [],
		timeOfMutation: new Date().toISOString(),
		eventName: 'jira-frontend.classic-software.controllers.rapidboard-state.issue.mutated',
		analyticsObject: {
			errorMsg: 'FAILED_SPLIT',
			error: true,
		},
	},
	ecClientIssueRank: {
		captureEventName: 'issueRankChanged',
		mutationSource: MutationSource.UPDATE,
		issueVersion: '1',
		matchStatus: [],
		timeOfMutation: new Date().toISOString(),
		eventName: 'jira-frontend.classic-software.controllers.rapidboard-state.issue.mutated',
		analyticsObject: {
			errorMsg: 'FAILED_ISSUE_RANK',
			error: true,
		},
	},
	ecClientIssueTransition: {
		captureEventName: 'issueTransitioned',
		mutationSource: MutationSource.UPDATE,
		issueVersion: '1',
		matchStatus: [],
		timeOfMutation: new Date().toISOString(),
		eventName: 'jira-frontend.classic-software.controllers.rapidboard-state.issue.mutated',
		analyticsObject: {
			errorMsg: 'FAILED_ISSUE_TRANSITIONED',
			error: true,
		},
	},
	ecClientRemovingIssuesFromSprint: {
		captureEventName: 'removedIssueFromSprint',
		mutationSource: MutationSource.UPDATE,
		issueVersion: '1',
		matchStatus: [],
		timeOfMutation: new Date().toISOString(),
		eventName: 'jira-frontend.classic-software.controllers.rapidboard-state.issue.mutated',
		analyticsObject: {
			errorMsg: 'FAILED_REMOVE_FROM_SPRINT',
			error: true,
		},
	},
	ecClientIssueFlagged: {
		captureEventName: 'flagIssueFromSprint',
		mutationSource: MutationSource.UPDATE,
		issueVersion: '1',
		matchStatus: [],
		timeOfMutation: new Date().toISOString(),
		eventName: 'jira-frontend.classic-software.controllers.rapidboard-state.issue.mutated',
		analyticsObject: {
			errorMsg: 'FAILED_FLAG_UNFLAG',
			error: true,
		},
	},
	ecClientIssueDelete: {
		captureEventName: 'issueDeleted',
		mutationSource: MutationSource.DELETE,
		issueVersion: '1',
		matchStatus: [],
		timeOfMutation: new Date().toISOString(),
		eventName: 'jira-frontend.classic-software.controllers.rapidboard-state.issue.mutated',
		analyticsObject: {
			errorMsg: 'FAILED_DELETE',
			error: true,
		},
	},
};
