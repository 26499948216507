import { useEffect, useState, useCallback } from 'react';
import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import { getPrefixedMarkName } from '@atlassian/jira-providers-spa-apdex-analytics/src/transform';
import { getRapidBoardDataResource } from '@atlassian/jira-router-resources-classic-projects/src/services/main.tsx';
import { isAllDataErrorValid } from '../../utils';
import { useRapidBoardResource } from '../resources';
import { useUrlState } from '../url-state';
import type { DeferredPromise, LoadDataProps } from './types';
import { resolveDeferredWithPromise, setupRapidBoardDataLoader } from './utils';

export const useRapidBoardDataLoader = () => {
	const { mode } = useUrlState();
	const { promise, loading, refresh, update } = useRapidBoardResource({
		resource: getRapidBoardDataResource(),
		retryPredicate: isAllDataErrorValid,
	});

	const [deferredPromise, setDeferredPromise] = useState<{
		deferred: DeferredPromise;
		callerMode: string;
	} | null>(null);
	const [initiateRefresh, setInitiateRefresh] = useState(false);

	const onLoadData = useCallback(
		({ isRefresh, mode: callerMode, deferred }: LoadDataProps) => {
			if (!deferred) {
				return;
			}
			setInitiateRefresh(mode === callerMode && isRefresh);
			setDeferredPromise({
				deferred,
				callerMode,
			});
			setMark(getPrefixedMarkName(`rapidboard_${mode}_data_requested`));
		},
		[mode],
	);

	const onUpdateData = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'data' implicitly has an 'any' type.
		(data) => {
			update((oldData) => ({
				// @ts-expect-error - TS2698 - Spread types may only be created from object types.
				...oldData,
				data: new Promise(
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					(resolve: (result: Promise<Record<any, any>> | Record<any, any>) => void) =>
						resolve(data),
				),
			}));
		},
		[update],
	);

	useEffect(() => {
		if (initiateRefresh && !loading) {
			refresh();
		}
		if (initiateRefresh) {
			setInitiateRefresh(false);
		}
	}, [initiateRefresh, loading, refresh]);

	useEffect(() => {
		if (deferredPromise === null || (initiateRefresh && !loading)) {
			return;
		}
		const { deferred, callerMode } = deferredPromise;
		if (mode === callerMode) {
			// @ts-expect-error - TS2345 - Argument of type 'Promise<unknown> | null' is not assignable to parameter of type 'Promise<{ data: Promise<Record<any, any>>; } | null> | null'.
			resolveDeferredWithPromise(promise, deferred);
			setDeferredPromise(null);
			setMark(getPrefixedMarkName(`rapidboard_${mode}_data_resolved`));
		}
	}, [promise, deferredPromise, mode, initiateRefresh, loading]);

	useEffect(() => {
		setupRapidBoardDataLoader(onLoadData, onUpdateData);
	}, [onLoadData, onUpdateData]);
};
