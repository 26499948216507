export const addMetaToHead = (name: string, value: string) => {
	const existingMetaTag = document.querySelector(`meta[name="${name}"]`);
	const metaTag = existingMetaTag || document.createElement('meta');

	metaTag.setAttribute('name', name);
	metaTag.setAttribute('content', value);

	if (!existingMetaTag) {
		document.head && document.head.insertBefore(metaTag, document.head.childNodes[0]);
	}

	return metaTag;
};

export const addDivToDom = (id: string) => {
	if (document.body && !document.getElementById(id)) {
		const documentBody: HTMLElement = document.body;
		const div = document.createElement('div');
		div.setAttribute('id', id);
		documentBody.insertBefore(div, documentBody.lastChild);
	}
};
