import camelCase from 'lodash/camelCase';
import {
	ROUTE_NAMES_RAPIDBOARD_BACKLOG,
	ROUTE_NAMES_RAPIDBOARD_USER_BACKLOG,
	ROUTE_NAMES_RAPIDBOARD_REPORT,
	ROUTE_NAMES_RAPIDBOARD_USER_REPORT,
} from '@atlassian/jira-common-constants/src/spa-routes';
import type { Route } from '@atlassian/jira-router';
import { WORK, PLAN, REPORT } from '../../constants';

// @ts-expect-error - TS2345 - Argument of type 'string | null | undefined' is not assignable to parameter of type 'string | undefined'.
export const toCamelCaseChartView = (chartView?: string | null) => camelCase(chartView);

export const getMode = (view: string) => {
	switch (view) {
		case 'planning':
		case 'planning.nodetail':
			return PLAN;
		case 'reporting':
			return REPORT;
		default:
			return WORK;
	}
};

export const getModeV2 = (route: Route | null) => {
	if (!route) {
		return WORK;
	}

	switch (route.name) {
		case ROUTE_NAMES_RAPIDBOARD_BACKLOG:
		case ROUTE_NAMES_RAPIDBOARD_USER_BACKLOG:
			return PLAN;
		case ROUTE_NAMES_RAPIDBOARD_REPORT:
		case ROUTE_NAMES_RAPIDBOARD_USER_REPORT:
			return REPORT;
		default:
			return WORK;
	}
};
