import { useEffect, useState, useRef } from 'react';
import { useFlagsService } from '@atlassian/jira-flags';
import {
	useResource,
	type RouteResource,
	type RouteResourceError,
	type RouteResourceResponse,
	type useResource as UseResource,
} from '@atlassian/jira-router';
import { ErrorFlag } from '../../common/ui/error-flag/index.tsx';

const ERROR_RETRY_LIMIT = 3;

type RapidBoardResourceResponse<T> = Flow.Diff<
	// @ts-expect-error - TS2344 - Type 'UseResourceHookResponse<unknown>' does not satisfy the constraint '{ key: string | undefined; expiresAt: RouteResourceTimestamp; accessedAt: RouteResourceTimestamp; }'.
	ReturnType<typeof UseResource>,
	{
		key: RouteResourceResponse<T>['key'];
		expiresAt: RouteResourceResponse<T>['expiresAt'];
		accessedAt: RouteResourceResponse<T>['accessedAt'];
	}
> & {
	hasFinishedRetrying: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useRapidBoardResource = <T extends Record<any, any>>({
	resource,
	shouldShowFlag = true,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	retryPredicate = (error: any) => error && error.statusCode !== 404,
}: {
	resource: RouteResource<T>;
	shouldShowFlag?: boolean;
	retryPredicate?: (arg1: RouteResourceError) => boolean;
}): RapidBoardResourceResponse<T> => {
	const { showFlag } = useFlagsService();
	const [retryNumber, setRetryNumber] = useState(0);
	const { promise, data, loading, error, refresh, update, clear, clearAll } =
		useResource<T>(resource);
	const flagId = useRef<unknown>(null);

	const shouldAttemptRefetch = !!(
		!loading &&
		error &&
		retryPredicate(error) &&
		retryNumber < ERROR_RETRY_LIMIT
	);

	useEffect(() => {
		if (shouldAttemptRefetch) {
			refresh();
			setRetryNumber(retryNumber + 1);
		}
	}, [refresh, retryNumber, shouldAttemptRefetch]);

	useEffect(() => {
		if (shouldShowFlag && !loading && retryNumber >= ERROR_RETRY_LIMIT && flagId.current === null) {
			flagId.current = showFlag(ErrorFlag());
		}
	}, [loading, retryNumber, shouldShowFlag, showFlag]);

	return {
		promise,
		data,
		loading,
		error,
		hasFinishedRetrying: !shouldAttemptRefetch,
		// @ts-expect-error - TS2328 - Types of parameters 'getNewData' and 'getNewData' are incompatible.
		update,
		refresh,
		clear,
		clearAll,
	};
};
