import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useEcClient } from '../../../ec-components';
import IssueMutationEventsFactoryBacklogAndBoard from '../index';

export const useIssueMutationEventsFactoryBacklogAndBoard = (props: {
	analyticsEventObj?: UIAnalyticsEvent;
}) => {
	const ecClientObj: {
		saveIssueMutationToCache: Function;
		saveIssueMutationsToCache: Function;
		searchAndReconcile: Function;
		getIssueMutationFromCache: Function;
	} = useEcClient();

	return (events: Object) =>
		new IssueMutationEventsFactoryBacklogAndBoard({ events, ...props, ecClientObj });
};
