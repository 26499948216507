import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { getBoardId } from '@atlassian/jira-common-navigation/src/get-scope-data';
import { getFeatureFlagValue, ff } from '@atlassian/jira-feature-flagging';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import IssueMutation from '../../issue-mutation';
import { createAnalyticsMetadata } from '../../storage/utils';
import type { IssueMutationEventsFactory } from '../types';
import { eventsDataBacklogAndBoard } from './constants';

export default class IssueMutationEventsFactoryBacklogAndBoard
	implements IssueMutationEventsFactory
{
	events: { bind: Function; unbind: Function };

	analyticsEventObj: UIAnalyticsEvent | undefined;

	ecClientIIC: Function;

	ecClientGIC: Function;

	ecClientEpicIssueAdded: Function;

	ecClientVersionIssueAdded: Function;

	ecClientVersion: Function;

	ecClientEpic: Function;

	ecClientSplitIssue: Function;

	ecClientIssueRank: Function;

	ecClientIssueTransition: Function;

	ecClientIssueFlagged: Function;

	ecClientRemovingIssuesFromSprint: Function;

	ecClientIssueDelete: Function;

	ecClientObj: { saveIssueMutationToCache: Function; saveIssueMutationsToCache: Function };

	constructor(props: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		events?: any;
		analyticsEventObj?: UIAnalyticsEvent;
		ecClientObj: { saveIssueMutationToCache: Function; saveIssueMutationsToCache: Function };
	}) {
		this.events = props.events;
		this.analyticsEventObj = props.analyticsEventObj;
		this.ecClientObj = props.ecClientObj;
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		this.ecClientIIC = () => {};
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		this.ecClientGIC = () => {};
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		this.ecClientEpicIssueAdded = () => {};
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		this.ecClientVersionIssueAdded = () => {};
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		this.ecClientVersion = () => {};
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		this.ecClientEpic = () => {};
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		this.ecClientSplitIssue = () => {};
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		this.ecClientIssueRank = () => {};
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		this.ecClientIssueTransition = () => {};
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		this.ecClientIssueFlagged = () => {};
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		this.ecClientRemovingIssuesFromSprint = () => {};
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		this.ecClientIssueDelete = () => {};
	}

	static generateIssueMutations(
		issueIds: number[],
		mutationSource: number,
		issueVersion: string,
		matchStatus: string[],
		timeOfMutation: string,
	): IssueMutation[] {
		const issueMutations: IssueMutation[] = [];
		issueIds.forEach((issueId: number) => {
			issueMutations.push(
				new IssueMutation(
					issueId.toString(),
					mutationSource,
					issueVersion,
					matchStatus,
					timeOfMutation,
				),
			);
		});
		return issueMutations;
	}

	/**
	 * A util method to create a handler used to save issue mutations in cache
	 * It also fires analytics events in case of errors
	 */
	async createHandler(
		experience: string,
		payload: number[] | string | object,
		handlerData: {
			mutationSource: number | object;
			issueVersion: string;
			matchStatus: string[];
			timeOfMutation: string;
			eventName: string;
			analyticsObject: Object;
		},
		event: { type: string },
	): Promise<void> {
		try {
			if (!this.analyticsEventObj) {
				const analyticsClient = await getAnalyticsWebClientPromise();
				// @ts-expect-error Type 'AnalyticsWebClient' is missing the following properties from type 'UIAnalyticsEvent': context, handlers, hasFired, _isUIAnalyticsEvent, and 5 more
				this.analyticsEventObj = analyticsClient.getInstance();
			}
			const customKeys = {
				boardId: getBoardId(window.location.href) || '',
				boardType: window.GH?.RapidBoard?.State?.getBoardTypeForAnalytics() || '',
			};
			const analyticsDataKey = {
				analyticsEventObj: this.analyticsEventObj,
				analyticsMetadata: { ...createAnalyticsMetadata(customKeys), scenario: event.type },
			};

			const experiences: string[] =
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(getFeatureFlagValue('odin.ec.client.integration.cbb', '') as string).split(',');

			if (!experiences || !experiences.includes(experience))
				return new Promise((resolve) => resolve());

			if (
				experience === 'inline-issue-create' ||
				experience === 'modify-version' ||
				experience === 'modify-epic' ||
				experience === 'delete-issues'
			) {
				await this.ecClientObj.saveIssueMutationToCache(
					new IssueMutation(
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						payload as string,
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						handlerData.mutationSource as number,
						handlerData.issueVersion,
						handlerData.matchStatus,
						handlerData.timeOfMutation,
					),
					analyticsDataKey,
					{ ...handlerData.analyticsObject },
				);

				return new Promise((resolve) => resolve());
			}
			if (
				experience === 'global-issue-create' &&
				/* eslint-disable @typescript-eslint/consistent-type-assertions */
				(
					(payload as { attributes: object; objectId: string }).attributes as {
						feature: string;
					}
				).feature !== 'IIC'
				/* eslint-enable @typescript-eslint/consistent-type-assertions */
			) {
				await this.ecClientObj.saveIssueMutationToCache(
					new IssueMutation(
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						(payload as { objectId: string }).objectId as string,
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						handlerData.mutationSource as number,
						handlerData.issueVersion,
						handlerData.matchStatus,
						handlerData.timeOfMutation,
					),
					analyticsDataKey,
					{ ...handlerData.analyticsObject },
				);

				return new Promise((resolve) => resolve());
			}
			if (experience === 'split-issues') {
				const issueMutations = IssueMutationEventsFactoryBacklogAndBoard.generateIssueMutations(
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					(payload as { oldIssueId: number; newIssueId: number[] }).newIssueId,
					/* eslint-disable @typescript-eslint/consistent-type-assertions */
					(
						handlerData.mutationSource as {
							originalIssue: number;
							newIssues: number;
						}
					) /* eslint-enable @typescript-eslint/consistent-type-assertions */.newIssues,
					handlerData.issueVersion,
					handlerData.matchStatus,
					handlerData.timeOfMutation,
				);

				if (ff('odin.enable.backend.cache.ec.client')) {
					issueMutations.push(
						new IssueMutation(
							/* eslint-disable @typescript-eslint/consistent-type-assertions */ (
								payload as { oldIssueId: number; newIssueId: number[] }
							) /* eslint-enable @typescript-eslint/consistent-type-assertions */.oldIssueId
								.toString(),

							/* eslint-disable @typescript-eslint/consistent-type-assertions */
							(
								handlerData.mutationSource as {
									originalIssue: number;
									newIssues: number;
								}
							) /* eslint-enable @typescript-eslint/consistent-type-assertions */.originalIssue,
							handlerData.issueVersion,
							handlerData.matchStatus,
							handlerData.timeOfMutation,
						),
					);

					await this.ecClientObj.saveIssueMutationsToCache(issueMutations, analyticsDataKey, {
						...handlerData.analyticsObject,
					});
				} else {
					await this.ecClientObj.saveIssueMutationToCache(
						new IssueMutation(
							/* eslint-disable @typescript-eslint/consistent-type-assertions */
							(
								payload as { oldIssueId: number; newIssueId: number[] }
							) /* eslint-enable @typescript-eslint/consistent-type-assertions */.oldIssueId
								.toString(),

							/* eslint-disable @typescript-eslint/consistent-type-assertions */
							(
								handlerData.mutationSource as {
									originalIssue: number;
									newIssues: number;
								}
							).originalIssue,
							/* eslint-enable @typescript-eslint/consistent-type-assertions */
							handlerData.issueVersion,
							handlerData.matchStatus,
							handlerData.timeOfMutation,
						),
						analyticsDataKey,
						{ ...handlerData.analyticsObject },
					);

					await this.ecClientObj.saveIssueMutationsToCache(issueMutations, analyticsDataKey, {
						...handlerData.analyticsObject,
					});
				}
				return new Promise((resolve) => resolve());
			}

			if (
				experience === 'flag-issues' ||
				experience === 'remove-from-sprint-issues' ||
				experience === 'rank-issues' ||
				experience === 'issue-drop-in-epic' ||
				experience === 'issue-drop-in-version' ||
				experience === 'drag-amongst-columns-issues'
			) {
				const issueMutations = IssueMutationEventsFactoryBacklogAndBoard.generateIssueMutations(
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					(payload as { issueIds: number[] }).issueIds,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					handlerData.mutationSource as number,
					handlerData.issueVersion,
					handlerData.matchStatus,
					handlerData.timeOfMutation,
				);

				await this.ecClientObj.saveIssueMutationsToCache(issueMutations, analyticsDataKey, {
					...handlerData.analyticsObject,
				});

				return new Promise((resolve) => resolve());
			}
			return new Promise((resolve) => resolve());
		} catch (e) {
			return new Promise((resolve) => resolve());
		}
	}

	/**
	 * A util method to define callbacks which are to be bind with the events.
	 * Each time the event is triggered, these callbacks will be fired in response to that
	 */
	createCallback(
		experience: string,
		handlerData: {
			mutationSource: number | object;
			issueVersion: string;
			matchStatus: string[];
			timeOfMutation: string;
			eventName: string;
			analyticsObject: Object;
		},
	): Function {
		if (experience === 'inline-issue-create')
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return (event: any, payload: { issueId: string }) => {
				this.createHandler(experience, payload?.issueId, handlerData, event);
			};
		if (experience === 'global-issue-create')
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return (event: any, payload: { objectId: string }) => {
				this.createHandler(experience, payload, handlerData, event);
			};

		if (experience === 'issue-drop-in-epic')
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return (event: any, payload: { id: number }) => {
				this.createHandler(experience, payload, handlerData, event);
			};

		if (experience === 'issue-drop-in-version')
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return (event: any, payload: { id: number }) => {
				this.createHandler(experience, payload, handlerData, event);
			};
		if (experience === 'modify-version')
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return (event: any, payload: number) => {
				this.createHandler(experience, payload?.toString(), handlerData, event);
			};

		if (experience === 'modify-epic')
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return (event: any, payload: number) => {
				this.createHandler(experience, payload?.toString(), handlerData, event);
			};

		if (experience === 'split-issues')
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return (event: any, payload: object) => {
				this.createHandler(experience, payload, handlerData, event);
			};

		if (experience === 'rank-issues')
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return (event: any, payload: number[]) => {
				this.createHandler(experience, payload, handlerData, event);
			};
		if (experience === 'drag-amongst-columns-issues')
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return (event: any, payload: number[]) => {
				this.createHandler(experience, payload, handlerData, event);
			};

		if (experience === 'flag-issues')
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return (event: any, payload: number[]) => {
				this.createHandler(experience, payload, handlerData, event);
			};

		if (experience === 'remove-from-sprint-issues')
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return (event: any, payload: number[]) => {
				this.createHandler(experience, payload, handlerData, event);
			};

		if (experience === 'delete-issues')
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return (event: any, payload: number[]) => {
				this.createHandler(experience, payload, handlerData, event);
			};

		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		return () => {};
	}

	/**
	 * A method to intialize all the callbacks
	 */
	initialize() {
		this.ecClientIIC = this.createCallback(
			'inline-issue-create',
			eventsDataBacklogAndBoard.ecClientIIC,
		);
		this.ecClientGIC = this.createCallback(
			'global-issue-create',
			eventsDataBacklogAndBoard.ecClientGIC,
		);
		this.ecClientEpic = this.createCallback('modify-epic', eventsDataBacklogAndBoard.ecClientEpic);
		this.ecClientEpicIssueAdded = this.createCallback(
			'issue-drop-in-epic',
			eventsDataBacklogAndBoard.ecClientEpicIssueAdded,
		);
		this.ecClientVersionIssueAdded = this.createCallback(
			'issue-drop-in-version',
			eventsDataBacklogAndBoard.ecClientVersionIssueAdded,
		);
		this.ecClientVersion = this.createCallback(
			'modify-version',
			eventsDataBacklogAndBoard.ecClientVersion,
		);

		this.ecClientSplitIssue = this.createCallback(
			'split-issues',
			eventsDataBacklogAndBoard.ecClientSplitIssue,
		);
		this.ecClientIssueRank = this.createCallback(
			'rank-issues',
			eventsDataBacklogAndBoard.ecClientIssueRank,
		);
		this.ecClientIssueTransition = this.createCallback(
			'drag-amongst-columns-issues',
			eventsDataBacklogAndBoard.ecClientIssueTransition,
		);

		this.ecClientIssueFlagged = this.createCallback(
			'flag-issues',
			eventsDataBacklogAndBoard.ecClientIssueFlagged,
		);

		this.ecClientRemovingIssuesFromSprint = this.createCallback(
			'remove-from-sprint-issues',
			eventsDataBacklogAndBoard.ecClientRemovingIssuesFromSprint,
		);

		this.ecClientIssueDelete = this.createCallback(
			'delete-issues',
			eventsDataBacklogAndBoard.ecClientIssueDelete,
		);
	}

	/**
	 * A method to bind the callbacks with the events that are being fired
	 */
	bind() {
		this.events.bind(eventsDataBacklogAndBoard.ecClientIIC.captureEventName, this.ecClientIIC);
		this.events.bind(eventsDataBacklogAndBoard.ecClientGIC.captureEventName, this.ecClientGIC);
		this.events.bind(
			eventsDataBacklogAndBoard.ecClientEpicIssueAdded.captureEventName,
			this.ecClientEpicIssueAdded,
		);
		this.events.bind(
			eventsDataBacklogAndBoard.ecClientVersionIssueAdded.captureEventName,
			this.ecClientVersionIssueAdded,
		);
		this.events.bind(
			eventsDataBacklogAndBoard.ecClientVersion.captureEventName,
			this.ecClientVersion,
		);
		this.events.bind(eventsDataBacklogAndBoard.ecClientEpic.captureEventName, this.ecClientEpic);
		this.events.bind(
			eventsDataBacklogAndBoard.ecClientSplitIssue.captureEventName,
			this.ecClientSplitIssue,
		);
		this.events.bind(
			eventsDataBacklogAndBoard.ecClientIssueRank.captureEventName,
			this.ecClientIssueRank,
		);
		this.events.bind(
			eventsDataBacklogAndBoard.ecClientIssueTransition.captureEventName,
			this.ecClientIssueTransition,
		);
		this.events.bind(
			eventsDataBacklogAndBoard.ecClientIssueFlagged.captureEventName,
			this.ecClientIssueFlagged,
		);

		this.events.bind(
			eventsDataBacklogAndBoard.ecClientRemovingIssuesFromSprint.captureEventName,
			this.ecClientRemovingIssuesFromSprint,
		);

		this.events.bind(
			eventsDataBacklogAndBoard.ecClientIssueDelete.captureEventName,
			this.ecClientIssueDelete,
		);
	}

	/**
	 * A method to unbind the callbacks with the events that are being fired
	 */
	unbind() {
		this.events.unbind(eventsDataBacklogAndBoard.ecClientIIC.captureEventName, this.ecClientIIC);
		this.events.unbind(eventsDataBacklogAndBoard.ecClientGIC.captureEventName, this.ecClientGIC);
		this.events.unbind(
			eventsDataBacklogAndBoard.ecClientEpicIssueAdded.captureEventName,
			this.ecClientEpicIssueAdded,
		);
		this.events.unbind(
			eventsDataBacklogAndBoard.ecClientVersionIssueAdded.captureEventName,
			this.ecClientVersionIssueAdded,
		);
		this.events.unbind(
			eventsDataBacklogAndBoard.ecClientVersion.captureEventName,
			this.ecClientVersion,
		);
		this.events.unbind(eventsDataBacklogAndBoard.ecClientEpic.captureEventName, this.ecClientEpic);
		this.events.unbind(
			eventsDataBacklogAndBoard.ecClientSplitIssue.captureEventName,
			this.ecClientSplitIssue,
		);
		this.events.unbind(
			eventsDataBacklogAndBoard.ecClientIssueRank.captureEventName,
			this.ecClientIssueRank,
		);
		this.events.unbind(
			eventsDataBacklogAndBoard.ecClientIssueTransition.captureEventName,
			this.ecClientIssueTransition,
		);
		this.events.unbind(
			eventsDataBacklogAndBoard.ecClientIssueFlagged.captureEventName,
			this.ecClientIssueFlagged,
		);

		this.events.unbind(
			eventsDataBacklogAndBoard.ecClientRemovingIssuesFromSprint.captureEventName,
			this.ecClientRemovingIssuesFromSprint,
		);

		this.events.unbind(
			eventsDataBacklogAndBoard.ecClientIssueDelete.captureEventName,
			this.ecClientIssueDelete,
		);
	}
}
